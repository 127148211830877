import React, { Component } from "react";
import Layout from "../layouts/ja";
import SEO from "../components/seo";
import langData from "../data/static-pages/pages/activation/ja";
import Activation from "../components/Activation";

const ActivationPage = (props) => {
  return (
    <Layout location={props.location} footer_margin={true}>
      <SEO title="Activation " keyword="Activation" decription="Activation" />
      <Activation
        location={props.location}
        langData={langData}
        loginUrl={"/ja/login"}
        redirectUrl={"/ja/create-new-password"}
        langKey={props.pageContext.langKey}
      />
    </Layout>
  );
};

export default ActivationPage;
